<template>
 
  
    <div>
      
     <br>

      <v-divider></v-divider>
        <v-card>
          <v-card-text>
          
            
            <h1 class="text-md-center">Congresos</h1> 
            
          </v-card-text>
        </v-card>
      <v-divider></v-divider> 
    <lista-programas></lista-programas>
    
      
     
     <br>
     </div>
</template>

<script>
  import listaProgramas from '../sections/programas/ListaCongresos.vue'
  
   
  export default {
    name: 'Home',

    components: {  
     listaProgramas,
     
      
    },
  }
</script>