<template>
 
    

    
        <v-container fluid :height='height'>
       
            <v-row dense>
                <v-col
                    v-for="galeria in users"
                    :key="galeria.galeria_id"
                    cols="12"
                  
                    
                >
                 <br>
                 <v-divider></v-divider>
                 <br>
                    <v-card rounded="xl" 
                     elevation="12"
                     class="mx-auto"
                    max-width="1500"
                     >
                    <v-card-title class="justify-center" v-text="galeria.titulo"></v-card-title>
                        <v-img
                         contain
                            :src="galeria.src"
                            class="white--text align-end"
                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                            height="500"
                            width="auto"
                        >
                            <v-card-title 
                             v-text="galeria.titulo"></v-card-title>
                            <!-- <div style="margin-bottom: 5px; margin-right: 5px" class="text-right"> -->
                                <!-- <v-avatar v-if="isAuthenticated && user.perfil" :color="galeria.status?'green':'red'" size="36">
                                    <span class="white--text text-h5">{{galeria.status?'A':'I'}} </span>
                                </v-avatar> -->
                            </div>
                        </v-img>

                        <v-card-text  >
                            
                                {{ galeria.sub_titulo }}
                           
                        </v-card-text>
                        
                        <v-divider></v-divider>
                       <v-card-actions class="justify-center">
                       <!-- aqui va el modal -->
                
                       <!-- aqui finaliza -->
                      
                        
                        </v-card-actions>

                        <v-container fluid>
      <v-row dense>
        <v-col
          v-for="card in galeria.album_cuerpo"
          :key="card.album_cuerpo_id"
          :cols="card.flex"
        >
          <v-card rounded="xl"   elevation="12">
            <v-img
            contain
            
              :src="card.src"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              height="200px"
            >
              <v-card-title  class="mr-3 hidden-xs-only hidden-sm-only"
               v-text="card.titulo"></v-card-title>
            </v-img>

            <v-card-actions>
              <v-spacer></v-spacer>
<!-- 
              <v-btn icon>
                <v-icon>mdi-heart</v-icon>
              </v-btn>-->

              <!-- <v-btn icon>
                <v-icon>mdi-bookmark</v-icon>
              </v-btn>  -->

              <v-btn  icon>
                <v-icon
                 color="blue"
                >mdi-share-variant</v-icon>
              </v-btn>
              <v-divider vertical></v-divider>
                 <v-btn  icon>
                <v-icon
                @click="compartir('F')"
                 color="light-blue darken-4"
                >mdi-facebook</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
                    </v-card>
                </v-col>
            </v-row>
                 <br>
                   <div style="min-height: 4px;">
                            <v-progress-linear
                             color="light-blue"
                            indeterminate
                            rounded
                            height="6"
                            v-model="progres.value"
                            :active="progres.show"
                            :indeterminate="progres.query"
                            :query="true"
                            ></v-progress-linear>
                        </div>
                <v-card>
                        <v-card-actions class="justify-center">
                           <v-pagination 
                            v-model="pagination.current"
                            :length="pagination.total"
                            @input="onPageChange">
                           </v-pagination>     
                        </v-card-actions>
                         
                </v-card>
            <br>
            <br>
        </v-container>
        
     
 


    
 
</template>

 

 
<script>

import auth from '../../../mixins/auth';

export default {
    data: () => ({
       url_:"",
        page:1,
      length:2,
       users: null,
        progres:{
                value: 0,
            query: false,
            show: true,
            interval: 0,
          },
       pagination: {
                current: 1,
                total: 0
            },
        galerias: [],
        galerias_imagenes:[],
        attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }),

    mixins: [auth],

     methods: {
       compartir(value){
         if (value =='F') {
            
        // !! The bellow shows what I may want.
            // return this.$router.getLink(route);
          window.open("http://www.facebook.com/share.php?u="+window.location.href+"&title=congresos", '_blank');
            //console.log(window.location.href);
           
         }
       },
       cargarProgres(){
              this.progres.query = true
                this.progres.show = true
                this.progres.value = 0

                setTimeout(() => {
                this.progres.query = false

                this.progres.interval = setInterval(() => {
                    // if (this.progres.value === 100) {
                    // clearInterval(this.progres.interval)
                    // this.show = false
                   // return setTimeout(this.cargarProgres, 2000)
                   // }
                    this.progres.value += 25
                }, 1000)
                }, 2500)
         },
        cargarGaleria() {
            
               this.cargarProgres();
             let that = this;
              var url =  this.url_ + "api/album?page="+this.pagination.current;
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                   that.users = response.data.albumes.data;
                    that.pagination.current = response.data.albumes.current_page;
                    that.pagination.total = response.data.albumes.last_page;
                   // that.galerias = response.data.galerias
               // console.log(that.users);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                }); 
                 clearInterval(this.progres.interval);
                this.show = false;
            },
             onPageChange() {
            this.cargarGaleria();
        },
        
  },
  mounted() {
      this.url_ = this.$store.state.link;
      this.cargarGaleria();
      //this.onPageChange();
  },
  computed: {
      
       height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
    
 
  }
};
</script>
