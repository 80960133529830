<template>
 
  
    <div>
    <br>

      <v-divider></v-divider>
      <v-card>
        <v-card-text>
        
          
          <h1 class="text-md-center">Ubicación</h1> 
          
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
    <lista-galeria></lista-galeria>
    
      
     
     <br> 
     </div>
</template>

<script>
  import ListaGaleria from '../sections/ubicacion/Ubicacion'
 
  
 

  export default {
    name: 'Home',

    components: {  
     ListaGaleria,
     
      
    },
     
  mounted() {
    //this.cargarGaleria();
  },
  }
</script>