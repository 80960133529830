<template>
 
    <div>
         <br>
         <br> 
        <v-row dense   v-for="(item, index) in userId" :key="index" v-show="show" >
      <v-col>
     
       
        <br>
        <v-divider></v-divider>
        <br>
         <v-card 
          class="mx-auto"
          elevation="12"
         
    
     
    max-width="1500"
  >
    
     <v-card-text>
        <div>   Publicado el {{ fecha }}</div>
        
      </v-card-text>
    <v-card-title> 
    <h1>{{ item.titulo }}</h1>
    </v-card-title>
    <br>
    <v-img 
    contain
    height="500"
    width="auto"
     :src="item.src"
    ></v-img>
 

   

    <v-card-text class="justify-end">
      
      
    <span style="white-space: pre-line;font-size:20px;" > {{ item.sub_titulo }}   </span>
    
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

   

     
   
  </v-card>

  
 
      </v-col>

      
      
    </v-row>

<br>
                <v-divider>
                </v-divider>
                <br>
        <v-card>
          
          </v-spacer>
          <v-card-actions class="justify-end">
          <h3>Compartir</h3> &nbsp;&nbsp;
          <v-divider vertical></v-divider>
           &nbsp;&nbsp;
             <v-btn icon="">
              <v-icon 
              color="light-blue darken-4"
               size="44px"
                @click="compartir('F')">
              mdi-facebook
              </v-icon></v-btn>

               <v-btn icon="">
              <v-icon 
              color="blue lighten-3"
               size="44px"
               @click="compartir('T')">
              mdi-twitter
              </v-icon></v-btn>
          </v-card-actions>
             
        </v-card>
                <br>
    
                <br>
                <v-divider>
                </v-divider>
                <br>
        <v-card>
          <v-card-text>
          
            
            <h3>Comentarios</h3> 
            
          </v-card-text>
        </v-card>
                <br>

               
        <v-row dense>
         <v-col
          v-for="(item, index) in comentario" :key="index" >  
                 <v-card
               
            class="mx-auto"           
          elevation="12"
            
           
            max-width="344"
        >
            <v-card-title  class="d-flex justify-space-between" >
             <v-img   
             contain
                height="60px"
                width="30px"
              
                :src="require('../../../assets/cropped-logo1.png')"
                ></v-img>
              <v-spacer></v-spacer>
            
           
            </v-card-title>

            <v-card-text class="text-h5 font-weight-bold">
            {{ item.comentario }}
            </v-card-text>

            <v-card-actions>
            <v-list-item class="grow">
                <v-list-item-avatar  >
                <v-img
                    class="elevation-6"
                    alt=""
                    src="https://avataaars.io/?avatarStyle=Circle&topType=ShortHairShortFlat&accessoriesType=Blank&hairColor=BrownDark&facialHairType=Blank&clotheType=BlazerShirt&eyeType=Default&eyebrowType=Default&mouthType=Smile&skinColor=Light"
                ></v-img>
                </v-list-item-avatar>

                <v-list-item-content>
                <v-list-item-title> {{ item.usuario }}</v-list-item-title>
                </v-list-item-content>

                <v-row
                align="center"
                justify="end"
                >
                <v-icon class="mr-1"
                color="primary">
                   mdi-calendar-multiple
                </v-icon>
                <span class="subheading mr-2"> {{ item.fecha }} -  {{ item.hora }}</span>
                
                </v-row>
            </v-list-item>
    </v-card-actions>
  </v-card>
  
         </v-col>  
    </v-row>
    <br>
    <v-divider>
    </v-divider>
    <br> 

            <v-row dense  v-show="show"  >
         <v-col class="justify-center"
          cols="12"
        >  
                 <v-card
               class="mx-auto"
               
                
                max-width="400"
        
            
           
           
        >
        <v-card-title primary-title>
          Agregar Comentario
        </v-card-title>
            <v-card-text>
                
            
       
                <v-form
                  
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <v-text-field
                    v-model="form.usuario"
                    :counter="15"
                    :rules="nameRules"
                    label="Usuario"
                    required
                    ></v-text-field>

                    <v-text-field
                    v-model="form.correo_electronico"
                    :rules="emailRules"
                    label="Correo"
                    required
                    ></v-text-field>

                        <v-text-field
                                    label="Comentario"
                                    placeholder="Comentario"
                                    outlined
                                    v-model="form.comentario"
                                     :counter="25"
                                     :rules="nameRules2"
                    ></v-text-field>
                    

                    <!-- <v-checkbox
                    v-model="checkbox"
                    :rules="[v => !!v || 'You must agree to continue!']"
                    label="Do you agree?"
                    required
                    ></v-checkbox> -->

                    <v-btn
                   
                    color="success"
                    class="mr-4"
                    @click="agregarComentario()"
                    >
                    Agregar comentario
                    </v-btn>

                    <!-- <v-btn
                    color="error"
                    class="mr-4"
                    @click="reset"
                    >
                    Reset Form
                    </v-btn>

                    <v-btn
                    color="warning"
                    @click="resetValidation"
                    >
                    Reset Validation
                    </v-btn> -->
                </v-form>
 </v-card-text>
            
            
            
  </v-card>
  
         </v-col>  
    </v-row>
    
                <!-- <v-card>
                        <v-card-actions class="justify-center">
                           <v-pagination 
                            v-model="pagination.current"
                            :length="pagination.total"
                            @input="onPageChange">
                           </v-pagination>     
                        </v-card-actions>
                         
                </v-card> -->
            <br>
            <br>
      
        
     
     </div>


    
 
</template>

 

 
<script>

import auth from '../../../mixins/auth';

export default {
    data: () => ({
      url_:"",
      show:false,
         valid: true,
      form:{
        articulo_cabecera_id:0,
          usuario: '',
          comentario: '',
           correo_electronico: '',
      },
     
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 15) || 'Name must be less than 15 characters',
      ],
        
      nameRules2: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 25) || 'Name must be less than 25 characters',
      ],
     
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
        userId:[],
        fecha:"",
        fecha_:"",
        options:{},
        comentario:[],
        page:1,
         show: false,
         loading: false,
         color_heart:"", 
      length:2,
       users: null,
       pagination: {
                current: 1,
                total: 0
            },
        galerias: [],
        galerias_imagenes:[],
        attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }),

    mixins: [auth],

     methods: {
         compartir(value){
         if (value =='F') {
         
          window.open("http://www.facebook.com/share.php?u="+"https://drnixonrivas.com/articulos"  + "&title=congresos", '_blank');
          
           
         }

          if (value =='T') {
         
          window.open("http://twitter.com/home?status="+window.location.href, '_blank');
          
           
         }
       },
         
         mostrarComentario(value){
             console.log(value);
             
              this.show=true;

         },
         cargarColores(value){
                if (value) {
                    
                }
         },
        cargarGaleria() {
            
            
             let that = this;
             var url =  this.url_ +"api/articulo?page="+this.pagination.current;
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success

                   that.users = response.data.articulos.data;
                    that.pagination.current = response.data.articulos.current_page;
                    that.pagination.total = response.data.articulos.last_page;
                   // that.galerias = response.data.galerias
               // console.log(that.users);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                }); 
            },
        cargarComentario(value) {
            
             this.$refs.form.reset()
             let that = this;
             var url =  this.url_ +"api/comentario/"+value;
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                   that.comentario = response.data.comentarios;
                 //  console.log( that.comentario);
                   
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                }); 
            },

           agregarComentario() {
            
            if (this.$refs.form.validate()) {
                  let that = this;
             var url =  this.url_ +"api/comentario";
 
              axios
                .post(url, that.form)
                .then(function (response) {
                  // handle success
                  //  console.log(response.data);
                   
                     that.cargarComentario(that.form.articulo_cabecera_id );
                   
                   
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                }); 
               
                 
             } 
         
            },
             onPageChange() {
            this.cargarGaleria();
        },
        
  },
  mounted() {
      this.url_ = this.$store.state.link;
      if (this.$route.params.userId !=null) {
          
  
   
    
     if ( this.$route.params.userId.articulo_cuerpo.length >0 ) {
         this.show=true
         this.form.articulo_cabecera_id = this.$route.params.userId.articulo_cabecera_id;
         this.userId = this.$route.params.userId.articulo_cuerpo;
         //  this.comentario = this.$route.params.userId.comentarios;
        this.fecha = this.$route.params.fecha;
        this.fecha_ = new Date(); 
          this.options = { year: 'numeric', month: 'long', day: 'numeric' };
        this.fecha =  this.fecha_.toLocaleDateString("es-ES", this.options);
        console.log( this.$route.params.userId);
        this.cargarComentario(this.form.articulo_cabecera_id );
     }
   
      
       } 
      //this.cargarGaleria();
      //this.onPageChange();
  },
  computed: {
      
       height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
    
 
  }
};
</script>
