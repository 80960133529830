<template>
 
  
    <div>
      
     <br>

      <v-divider></v-divider>
        <v-card>
          <v-card-text>
          
            
            <h1 class="text-md-center">Articulos</h1> 
            
          </v-card-text>
        </v-card>
      <v-divider></v-divider> 
  <lista-articulos></lista-articulos>
    
      
     
     <br>
     </div>
</template>

<script>
  import ListaArticulos from '../sections/eventos/ListaArticulos.vue'
  
   
  export default {
    name: 'Home',

    components: {  
     ListaArticulos,
     
      
    },
  }
</script>