<template>
 
    <div>
        


    
        
        <v-row dense >
      <v-col
      v-for="(item, index) in users" :key="index"
          
        
       
      >
        <br>
        <v-divider></v-divider>
        <br>
         <v-card 
          class="mx-auto"
          elevation="12"
          rounded="xl"
    :loading="loading"
     
    max-width="344"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>
   
    <v-img v-if="item.articulo_cuerpo.length >0 "
      height="250"
     :src="item.articulo_cuerpo[0].src"
    ></v-img>

     <v-img  v-else
      height="250"
     :src="require('../../../assets/cropped-logo1.png')"
    ></v-img>

    <v-card-title> {{ item.titulo }}</v-card-title>

    <v-card-text>
      <v-row
        align="center"
       
      >
      
 
      </v-row>
        <br>

      <div> {{ item.sub_titulo.slice(0, 30) }}..</div>
    </v-card-text>

    <v-divider class="mx-4"></v-divider>

   

     
    <v-card-actions>
      <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>  
        &nbsp;&nbsp;
        <!-- <v-divider vertical ></v-divider> -->
        &nbsp;&nbsp;
        <v-btn icon>
         <v-icon
         size="30px"
         color="purple"
         
         > mdi-message-reply </v-icon>{{ item.count_comentario }}
        </v-btn>
        &nbsp;&nbsp;
        <!-- <v-divider vertical ></v-divider> -->
       
      
        <v-btn   @click="reaccion_update(item)" icon v-if="item.reaccion != null">
        <!-- {{ item.color_figura = "red" }} -->
         <v-icon
         size="30px"
         
       
          :color="item.color_figura"
         >mdi-heart</v-icon>{{ item.count_reaccion }}
        </v-btn>

         <v-btn  @click="reaccion_update(item)" icon v-else>
          <!-- {{ item.color_figura = "gray" }} -->
         <v-icon
         size="30px"
              

         
            :color="item.color_figura"
         >mdi-heart</v-icon>{{ item.count_reaccion }}
        </v-btn>
       
&nbsp;&nbsp;
<router-link   :to="{ name: 'articulos_all', params: { userId:item }}">
 <v-btn
        color="deep-purple lighten-2"
        text
        
        
      >
        Ver articulo
      </v-btn>
      </router-link>
     
    </v-card-actions>
    
  </v-card>


         
       
     
      </v-col>
      
    </v-row>
    
                <br>
                   <div style="min-height: 4px;">
                            <v-progress-linear
                             color="light-blue"
                            indeterminate
                            rounded
                            height="6"
                            v-model="progres.value"
                            :active="progres.show"
                            :indeterminate="progres.query"
                            :query="true"
                            ></v-progress-linear>
                        </div>
                <v-card>
                        <v-card-actions class="justify-center">
                           <v-pagination 
                            v-model="pagination.current"
                            :length="pagination.total"
                            @input="onPageChange">
                           </v-pagination>     
                        </v-card-actions>
                         
                </v-card>
            <br>
            <br>
      
        
     
     </div>


    
 
</template>

 

 
<script>

import auth from '../../../mixins/auth';

export default {
    data: () => ({
       url_:"",
       progres:{
                value: 0,
            query: false,
            show: true,
            interval: 0,
          },
        userId:[],
        page:1,
        color_figura:"",
         show: false,
         loading: false,
         color_heart:"", 
      length:2,
       users: null,
       pagination: {
                current: 1,
                total: 0
            },
        galerias: [],
        galerias_imagenes:[],
        attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
    }),

    mixins: [auth],

     methods: {
        cargarProgres(){
              this.progres.query = true
                this.progres.show = true
                this.progres.value = 0

                setTimeout(() => {
                this.progres.query = false

                this.progres.interval = setInterval(() => {
                    // if (this.progres.value === 100) {
                    // clearInterval(this.progres.interval)
                    // this.show = false
                   // return setTimeout(this.cargarProgres, 2000)
                   // }
                    this.progres.value += 25
                }, 1000)
                }, 2500)
         },
       reaccion_update(value){
         console.log(value);
         value.reaccion =! value.reaccion 
         if (value.reaccion) {
           value.color_figura = "red";

         }else{
            value.color_figura = "gray";
         }

          let that = this;
             var url =  this.url_ + "api/reaccion/"+value.articulo_cabecera_id;
 
              axios
                .put(url,value)
                .then(function (response) {
                  // handle success
                  console.log(response.data);
                  
                   value.count_reaccion = response.data.reacciones
                   // that.galerias = response.data.galerias
               // console.log(that.users);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                }); 



           console.log(value.reaccion);
       },
         mostrarComentario(value){
             console.log(value);
             
              this.show=true;

         },
         cargarColores(value){
                if (value) {
                    
                }
         },
        cargarGaleria() {
            
             this.cargarProgres();
             let that = this;
             var url =  this.url_ + "api/articulo?page="+this.pagination.current;
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                   that.users = response.data.articulos.data;
                    that.pagination.current = response.data.articulos.current_page;
                    that.pagination.total = response.data.articulos.last_page;
                   // that.galerias = response.data.galerias
               // console.log(that.users);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                }); 
                 clearInterval(this.progres.interval);
                this.show = false
            },
             onPageChange() {
            this.cargarGaleria();
        },
        
  },
  mounted() {
       this.url_ = this.$store.state.link;
      this.cargarGaleria();
      //this.onPageChange();
  },
  computed: {
      // color_computed(value){
      //   if (!value) {
      //     return this.color_figura = "red"
      //   } else {
      //       return this.color_figura = "gray"
      //   }
      // },
      
       height () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 220
          case 'sm': return 400
          case 'md': return 500
          case 'lg': return 600
          case 'xl': return 800
        }
      },
    
 
  }
};
</script>
