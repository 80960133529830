<template>
  <div
    
    
  >
    <v-card
      class="flex"
      flat
      tile
    
    >
      <v-card-title >
        
   <v-row>
  
 
      <v-col
        
         
         >
            
    <v-card-title primary-title>
         DONDE ESTAMOS?
       </v-card-title>
        
      
      <v-card-text >  
       <v-timeline
        align-top
        dense
      >
        


          <v-timeline-item
          color="amber"
          small
        >
          <v-row class="pt-1">
            <v-col cols="2">
              <v-icon  color="primary" size="35px" >mdi-hospital-building</v-icon>
            </v-col>
            <v-col>
              <strong>{{ informacion.direccion_empresa }} - {{ informacion.direccion_oficina }}</strong>
              
            </v-col>
          </v-row>
        </v-timeline-item>

         <v-timeline-item
          color="light-green accent-3"
          small
        >
          <v-row class="pt-1">
            <v-col cols="2">
              <v-icon color="primary" size="35px"  >mdi-phone-lock</v-icon>
            </v-col>
            <v-col>
              <strong>{{ informacion.convencional }}</strong>
              
            </v-col>
          </v-row>
        </v-timeline-item>

         <v-timeline-item
          color="deep-orange darken-1"
          small
        >
          <v-row class="pt-1">
            <v-col cols="2">
              <v-icon  color="primary" size="35px" >mdi-cellphone-sound</v-icon>
            </v-col>
            <v-col>
              <strong>{{ informacion.celular }}</strong>
              
            </v-col>
          </v-row>
        </v-timeline-item>


          <v-timeline-item
          color="yellow accent-2"
          small
        >
          <v-row class="pt-1">
            <v-col cols="2">
              <v-icon color="primary" size="35px" >mdi-email</v-icon>
            </v-col>
            <v-col>
              <strong>{{ informacion.correo_electronico }}</strong>
              
            </v-col>
          </v-row>
        </v-timeline-item>

       
 
      </v-timeline>
      </v-card-text >
  
        

      <v-spacer></v-spacer>
 

 
      </v-col>

      
 
      </v-col>
      
      

       <v-col
           
        
         >
         <v-card>
         <v-card-text>
              <div v-html="informacion.google_map" >
                  
              </div>  
         </v-card-text>
              
         </v-card>
  
                 
             

        
            </v-col>

          <v-col
           
          md="1"
         >
         <v-card>
        
              
         </v-card>
  
                 
             

        
            </v-col>

 

   </v-row>
      </v-card-title>
 
<v-divider >
</v-divider>
 
      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} — <strong> {{ informacion.nombre_gerente }} </strong>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'HomeFooter',

    data: () => ({
        url_:"",
      color:"light-blue white--text",
          icons: [
        
      ],
      informacion:[],
      horario:[
          {dias:'Lunes',horaInicio:'10:00 am', horaFin:'17:00 pm',color:''},
          {dias:'Martes',horaInicio:'10:00 am', horaFin:'17:00 pm'},
          {dias:'Miercoles',horaInicio:'10:00 am', horaFin:'17:00 pm'},
          {dias:'Jueves',horaInicio:'10:00 am', horaFin:'17:00 pm'},
          {dias:'Viernes',horaInicio:'10:00 am', horaFin:'17:00 pm'},
          {dias:'Sábado',horaInicio:'10:00 am', horaFin:'17:00 pm'},
          {dias:'Domingo',horaInicio:'10:00 am', horaFin:'17:00 pm'},


      ]

    }
    ),
     methods: {
          cargarGaleria() {
          //  console.log('holis');
            
             let that = this;
             var url =  this.url_ + "api/carrusel";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.items = response.data.carruseles
               // console.log(that.items);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
        cargarInformacion() {
           
            
             let that = this;
             var url =  this.url_ + "api/empresa";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.informacion = response.data.empresa;
                //console.log(that.informacion);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
              CargarRedes() {
           
            
             let that = this;
             var url =  this.url_ + "api/red_social";
 
              axios
                .get(url)
                .then(function (response) {
                  // handle success
                    that.icons = response.data.redes_sociales;
              //  console.log(that.icons);
                
                })
                .catch(function (error) { 
                  // handle error
                  console.log(error);
                });
            },
    },
    mounted() {
        this.url_ = this.$store.state.link;
         this.cargarGaleria();
         this.cargarInformacion();
         this.CargarRedes();
    },

  }
</script>
 