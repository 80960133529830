<template>
 
  
    <div>
      
<!--     
    <banner></banner> -->
    <agenda></agenda>

    
      
     
     <br> 
     </div>
</template>

<script>
 
  
  import Agenda from '../sections/agenda/Agenda.vue'

  
   
  export default {
    name: 'Home',

    components: {  
     
     Agenda,
     
      
    },
  }
</script>